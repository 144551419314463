import React from 'react';
import { Button, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { formValueSelector } from 'redux-form';
import { closeDialog as closeDialogFunc, openDialog } from '../ducks/dialogSlice';
import { Dialog } from './Dialog';
import { Dialogs } from '../constants/Dialogs';
import { I18nKeys } from '../constants/I18nKeys';
import { useAppDispatch, useAppSelector } from '../hooks';
import { FormData, SetupPaymentIntegrationForm } from './SetupPaymentIntegrationForm';
import { SetupPaymentIntegrationFormFields } from '../constants/FormFields';
import { Forms } from '../constants/Forms';
import { AppState } from '../types/AppState';
import { SetupPaymentIntegrationOptions } from '../types/DialogState';

const useStyles = makeStyles(() => ({
  dialogTitle: {
    fontSize: '20px',
    fontWeight: '500',
    lineHeight: '23.4px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: '#000000DE',
  },
  dialogSubtitle: {
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '20px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: '#000000DE',
    marginBottom: '16px',
  },
}));

export const SetupPaymentIntegrationDialog: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { options = {} } = useAppSelector((state) => state.dialog || {});
  const { legalEntityName, shopperStatement } = (options as SetupPaymentIntegrationOptions) || {
    legalEntityName: '',
    shopperStatement: '',
  };

  const legalEntityNameForm = useAppSelector((state: AppState) =>
    formValueSelector(Forms.SetupPaymentIntegration)(state, SetupPaymentIntegrationFormFields.LegalEntityName),
  );
  const shopperStatementForm = useAppSelector((state: AppState) =>
    formValueSelector(Forms.SetupPaymentIntegration)(state, SetupPaymentIntegrationFormFields.ShopperStatement),
  );

  const onSetup = () => {
    if (legalEntityNameForm.length > 0 && shopperStatementForm.length > 0) {
      dispatch(closeDialogFunc());
      dispatch(
        openDialog({
          dialog: Dialogs.SetupPaymentIntegrationConfirmation,
          options: { legalEntityName: legalEntityNameForm, shopperStatement: shopperStatementForm },
        }),
      );
    }
  };

  const initialValues = {
    [SetupPaymentIntegrationFormFields.LegalEntityName]: legalEntityName || '',
    [SetupPaymentIntegrationFormFields.ShopperStatement]: shopperStatement || '',
  } as FormData;

  return (
    <Dialog dialogKey={Dialogs.SetupPaymentIntegration}>
      <DialogTitle className={classes.dialogTitle}>{t(I18nKeys.SetupPaymentIntegrationDialogTitle)}</DialogTitle>
      <DialogContent>
        <Typography className={classes.dialogSubtitle}>{t(I18nKeys.SetupPaymentIntegrationDialogSubtitle)}</Typography>

        <SetupPaymentIntegrationForm initialValues={initialValues} />
      </DialogContent>
      <DialogActions style={{ padding: '0px 8px 8px 8px' }}>
        <Button
          onClick={(): void => {
            dispatch(closeDialogFunc());
          }}
          color="primary"
        >
          {t(I18nKeys.DialogCancelButton)}
        </Button>
        <Button onClick={onSetup} color="primary">
          {t(I18nKeys.DialogSetupButton)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
