import { toast } from 'react-toastify';
import { reset, SubmissionError } from 'redux-form';
import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { QueryActionCreatorResult } from '@reduxjs/toolkit/dist/query/core/buildInitiate';
import { SALESVIEW } from '../constants/App';
import { Dialogs } from '../constants/Dialogs';
import {
  I18nDialogFormFields,
  LanguageDialogFormFields,
  SceneEnvironmentDialogFormFields,
  WhatsNewDialogFormFields,
} from '../constants/FormFields';
import { Forms } from '../constants/Forms';
import { defaultI18nNamespace } from '../constants/I18n';
import { I18nKeys } from '../constants/I18nKeys';
import { S3Buckets } from '../constants/S3';
import { closeDialog, openDialog } from '../ducks/dialogSlice';
import {
  addWhatsNew,
  addWhatsNewError,
  editWhatsNew,
  editWhatsNewError,
  SettingsActionTypes,
  openSalesViewWhatsNewSuccess,
  openSalesViewWhatsNewError,
  fetchI18nValuesSuccess,
  fetchI18nValuesError,
  fetchSceneEnvironment as fetchSceneEnvironmentAction,
  fetchSceneEnvironmentSuccess,
  fetchSceneEnvironmentError,
  addSceneEnvironment,
  editSceneEnvironment,
  editSceneEnvironmentError,
  addSceneEnvironmentError,
  removeSceneEnvironmentSuccess,
} from '../ducks/settings';
import { i18n } from '../i18n';
import { AppState } from '../types/AppState';
import { I18n } from '../types/I18n';
import { SceneEnvironment } from '../types/SceneEnvironment';
import { SettingsState } from '../types/SettingsState';
import { WhatsNew } from '../types/WhatsNew';
import { sanitizeInput } from '../utils/inputUtils';
import { extractErrorProps } from '../utils/errorUtils';
import { s3Api } from '../services/s3Api';
import { settingApi } from '../services/settingApi';
import { unknownGroup } from '../constants/Group';
import { Group } from '../types/Group';

const defaultErrorMessage = 'Something went wrong. Please try again.';

function* saveWhatsNew({
  reject,
  resolve,
  values: {
    [WhatsNewDialogFormFields.ClientId]: clientId,
    [WhatsNewDialogFormFields.Id]: id,
    [WhatsNewDialogFormFields.Date]: date,
    [WhatsNewDialogFormFields.Title]: title,
    [WhatsNewDialogFormFields.Message]: message,
  },
}: any): Generator {
  try {
    const { group: { groupId } = unknownGroup } = (yield select(({ currentUser }: AppState) => currentUser)) as {
      group: Group;
    };

    const sanitizedTitle = sanitizeInput(title);
    const sanitizedMessage = sanitizeInput(message);
    if (!id) {
      yield put(addWhatsNew());

      const addWhatsNewFetch = (yield put(
        settingApi.endpoints.addWhatsNew.initiate({
          groupId,
          clientId,
          whatsNew: [{ date, title: sanitizedTitle, message: sanitizedMessage } as WhatsNew],
        }) as any,
      )) as QueryActionCreatorResult<any>;
      addWhatsNewFetch.unsubscribe();

      yield addWhatsNewFetch;

      yield call(resolve);

      yield put(closeDialog());

      yield put(reset(Forms.WhatsNew));
    } else {
      const { whatsNewDialog } = (yield select(({ settings }: AppState) => settings)) as SettingsState;
      yield put(editWhatsNew(whatsNewDialog));

      const updateWhatsNewFetch = (yield put(
        settingApi.endpoints.updateWhatsNew.initiate({
          groupId,
          clientId,
          id,
          whatsNew: { id, date, title: sanitizedTitle, message: sanitizedMessage } as WhatsNew,
        }) as any,
      )) as QueryActionCreatorResult<any>;
      updateWhatsNewFetch.unsubscribe();

      yield updateWhatsNewFetch;

      yield call(resolve);

      yield put(closeDialog());

      yield put(reset(Forms.WhatsNew));
    }
  } catch (error) {
    yield put(id ? editWhatsNewError() : addWhatsNewError());

    const { errorMessage = defaultErrorMessage } = extractErrorProps(error);
    yield call(reject, new SubmissionError({ _error: errorMessage }));
  }
}

function* removeWhatsNew({ payload: { whatsNew } }: any): Generator {
  try {
    const { id, clientId } = whatsNew;
    const { group: { groupId } = unknownGroup } = (yield select(({ currentUser }: AppState) => currentUser)) as {
      group: Group;
    };
    const deleteWhatsNewFetch = (yield put(
      settingApi.endpoints.deleteWhatsNew.initiate({
        groupId,
        clientId,
        id,
      }) as any,
    )) as QueryActionCreatorResult<any>;
    deleteWhatsNewFetch.unsubscribe();

    yield deleteWhatsNewFetch;
  } catch (error) {
    const { errorMessage = defaultErrorMessage } = extractErrorProps(error);
    yield call(toast.error, errorMessage);
  }
}

function* openSalesViewWhatsNewDialog(): Generator {
  try {
    const latestSalesViewWhatsNewFetch = (yield put(
      settingApi.endpoints.getLatestWhatsNew.initiate({
        clientId: SALESVIEW,
        count: 5,
      }) as any,
    )) as QueryActionCreatorResult<any>;
    latestSalesViewWhatsNewFetch.unsubscribe();

    const { data: whatsNew = [] } = (yield latestSalesViewWhatsNewFetch) as { data: WhatsNew[] };
    yield put(openSalesViewWhatsNewSuccess(whatsNew));
    yield put(openDialog({ dialog: Dialogs.WhatsNewSalesView }));
  } catch (error) {
    const { errorMessage = defaultErrorMessage } = extractErrorProps(error);
    yield put(openSalesViewWhatsNewError());
    yield call(toast.error, errorMessage);
  }
}

function* fetchSceneEnvironment({ payload: { clientId } }: any): Generator {
  try {
    const getSceneEnvironmentFetch = (yield put(
      settingApi.endpoints.listSceneEnvironments.initiate({
        clientId,
      }) as any,
    )) as QueryActionCreatorResult<any>;
    getSceneEnvironmentFetch.unsubscribe();

    const { data: sceneEnvironment = [] } = (yield getSceneEnvironmentFetch) as {
      data: SceneEnvironment[];
    };
    yield put(fetchSceneEnvironmentSuccess(sceneEnvironment || []));
  } catch (error) {
    yield put(fetchSceneEnvironmentError());
    const { errorMessage = defaultErrorMessage } = extractErrorProps(error);
    yield call(toast.error, errorMessage);
  }
}

const validateSceneEnvironment = (
  sceneEnvironment: SceneEnvironment,
  sceneEnvironmentList: SceneEnvironment[],
  isNew = false,
): any => {
  const errors: any = {};

  if (!sceneEnvironment.key) {
    errors[SceneEnvironmentDialogFormFields.Key] = i18n.t(I18nKeys.Required);
  }
  if (!sceneEnvironment.label) {
    errors[SceneEnvironmentDialogFormFields.Label] = i18n.t(I18nKeys.Required);
  }
  if (!sceneEnvironment.fileUrl) {
    errors[SceneEnvironmentDialogFormFields.FileUrl] = i18n.t(I18nKeys.Required);
  }
  if (!sceneEnvironment.previewUrl) {
    errors[SceneEnvironmentDialogFormFields.PreviewUrl] = i18n.t(I18nKeys.Required);
  }
  if (isNew && sceneEnvironmentList.some((scene) => scene.key === sceneEnvironment.key)) {
    errors[SceneEnvironmentDialogFormFields.Key] = i18n.t(I18nKeys.KeyInUse);
  }

  return errors;
};

function* saveSceneEnvironment({
  reject,
  resolve,
  values: {
    [SceneEnvironmentDialogFormFields.ClientId]: clientId,
    [SceneEnvironmentDialogFormFields.Key]: key,
    [SceneEnvironmentDialogFormFields.Label]: label,
    [SceneEnvironmentDialogFormFields.FileUrl]: fileUrl,
    [SceneEnvironmentDialogFormFields.PreviewUrl]: previewUrl,
    [SceneEnvironmentDialogFormFields.MaxCameraDistanceMultiplier]: maxCameraDistanceMultiplier,
    [SceneEnvironmentDialogFormFields.MaxDiagonalBuildingLength]: maxDiagonalBuildingLength,
  },
}: any): Generator {
  const { sceneEnvironmentDialog } = (yield select(({ settings }: AppState) => settings)) as SettingsState;

  const sanitizedKey = sanitizeInput(key).trim();
  const sanitizedLabel = sanitizeInput(label).trim();
  try {
    const newSceneEnvironment: SceneEnvironment = {
      key: sanitizedKey,
      label: sanitizedLabel,
      fileUrl: fileUrl.trim(),
      previewUrl: previewUrl.trim(),
      maxCameraDistanceMultiplier,
      maxDiagonalBuildingLength,
    };

    const { sceneEnvironment } = (yield select(({ settings }: AppState) => settings)) as SettingsState;
    const errors: any = validateSceneEnvironment(
      newSceneEnvironment,
      sceneEnvironment,
      !sceneEnvironmentDialog || sceneEnvironmentDialog.isNew,
    );

    if (Object.keys(errors).length > 0) {
      yield call(reject, new SubmissionError(errors));
    } else {
      if (!sceneEnvironmentDialog || sceneEnvironmentDialog.isNew) {
        yield put(addSceneEnvironment());
        const addSceneEnvironmentFetch = (yield put(
          settingApi.endpoints.addSceneEnvironment.initiate({
            clientId,
            sceneEnvironment: [newSceneEnvironment],
          }) as any,
        )) as QueryActionCreatorResult<any>;
        addSceneEnvironmentFetch.unsubscribe();

        yield addSceneEnvironmentFetch;
      } else {
        const { group: { groupId } = unknownGroup } = (yield select(({ currentUser }: AppState) => currentUser)) as {
          group: Group;
        };

        yield put(editSceneEnvironment(sceneEnvironmentDialog));
        const updateSceneEnvironmentFetch = (yield put(
          settingApi.endpoints.updateSceneEnvironment.initiate({
            groupId,
            clientId,
            sceneEnvironmentKey: sceneEnvironmentDialog.key,
            sceneEnvironment: {
              ...newSceneEnvironment,
              key: sceneEnvironmentDialog.key,
            },
          }) as any,
        )) as QueryActionCreatorResult<any>;
        updateSceneEnvironmentFetch.unsubscribe();

        yield updateSceneEnvironmentFetch;
      }
      yield call(resolve);

      yield put(fetchSceneEnvironmentAction(clientId));

      yield put(closeDialog());

      yield put(reset(Forms.SceneEnvironment));
    }
  } catch (error) {
    yield put(sceneEnvironmentDialog ? editSceneEnvironmentError() : addSceneEnvironmentError());

    const { errorMessage = defaultErrorMessage } = extractErrorProps(error);
    yield call(reject, new SubmissionError({ _error: errorMessage }));
  }
}

function* removeSceneEnvironment({ payload: { sceneEnvironment } }: any): Generator {
  try {
    const { group: { groupId } = unknownGroup } = (yield select(({ currentUser }: AppState) => currentUser)) as {
      group: Group;
    };

    const { key, clientId } = sceneEnvironment;
    yield put(removeSceneEnvironmentSuccess(sceneEnvironment));
    const deleteSceneEnvironmentFetch = (yield put(
      settingApi.endpoints.deleteSceneEnvironment.initiate({
        groupId,
        clientId,
        sceneEnvironmentKey: key,
      }) as any,
    )) as QueryActionCreatorResult<any>;
    deleteSceneEnvironmentFetch.unsubscribe();

    yield deleteSceneEnvironmentFetch;
  } catch (error) {
    const { errorMessage = defaultErrorMessage } = extractErrorProps(error);
    yield call(toast.error, errorMessage);
  }
}

function* fetchI18nValues({ payload: { language } }: any): Generator {
  try {
    const i18nFilePath = `salesview/${language}/${defaultI18nNamespace}.json`;

    const getFileFetch = (yield put(
      s3Api.endpoints.getFile.initiate({
        bucket: S3Buckets.IdeaRoomI18n,
        path: i18nFilePath,
      }) as any,
    )) as QueryActionCreatorResult<any>;
    getFileFetch.unsubscribe();
    // Wait until the table data is fetched and the grid is updated
    const {
      data: { file },
    } = (yield getFileFetch) as { data: { file: any } };

    // Convert i18nKeys enum to a json object with empty values
    const i18nKeys = Object.values(I18nKeys).reduce((acc, key) => {
      acc[key] = '';
      return acc;
    }, {} as I18n);

    // Merge i18nKeys with i18n
    const i18nValues = { ...i18nKeys, ...file };

    yield put(fetchI18nValuesSuccess(i18nValues));
  } catch (error) {
    yield put(fetchI18nValuesError());
    const { errorMessage = defaultErrorMessage } = extractErrorProps(error);
    yield call(toast.error, errorMessage);
  }
}

function* saveSalesViewI18n({
  reject,
  resolve,
  values: {
    [I18nDialogFormFields.ClientId]: clientId,
    [I18nDialogFormFields.Language]: language,
    [I18nDialogFormFields.Key]: key,
    [I18nDialogFormFields.Override]: override,
  },
}: any): Generator {
  try {
    if (clientId === SALESVIEW) {
      if (!key) {
        throw new Error('Key was not passed in.');
      }

      // Get the i18n values from the i18n dialog and state
      const { i18n: i18nFromSettings } = (yield select(({ settings }: AppState) => settings)) as SettingsState;

      // Add or replace the value within the i18n file
      const i18nValues = { ...i18nFromSettings, [key]: override };

      const name = `${defaultI18nNamespace}.json`;
      const path = `${SALESVIEW}/${language}`;
      const contentType = 'application/json';

      const uploadFilePost = (yield put(
        s3Api.endpoints.uploadFile.initiate({
          bucket: S3Buckets.IdeaRoomI18n,
          path,
          file: {
            name,
            content: JSON.stringify(i18nValues),
            contentType,
          },
        }) as any,
      )) as QueryActionCreatorResult<any>;
      uploadFilePost.unsubscribe();
      // Wait until the table data is fetched and the grid is updated
      yield uploadFilePost;

      yield put(fetchI18nValuesSuccess(i18nValues));

      yield call(resolve);

      yield put(closeDialog());

      yield put(reset(Forms.I18n));
    }
  } catch (error) {
    const { errorMessage = defaultErrorMessage } = extractErrorProps(error);
    yield call(reject, new SubmissionError({ _error: errorMessage }));
  }
}

function* deleteI18nKey({
  payload: {
    i18nFormData: { [I18nDialogFormFields.Language]: language, [I18nDialogFormFields.Key]: key },
  },
}: any): Generator {
  try {
    if (!key) {
      throw new Error('Key was not passed in.');
    }

    // Get the i18n values from the i18n dialog and state
    let { i18n: i18nFromSettings } = (yield select(({ settings }: AppState) => settings)) as SettingsState;

    if (Object.values(I18nKeys).includes(key)) {
      i18nFromSettings = { ...i18nFromSettings, [key]: '' };
    } else {
      delete i18nFromSettings[key];
    }

    const name = `${defaultI18nNamespace}.json`;
    const path = `${SALESVIEW}/${language}`;
    const contentType = 'application/json';

    const uploadFilePost = (yield put(
      s3Api.endpoints.uploadFile.initiate({
        bucket: S3Buckets.IdeaRoomI18n,
        path,
        file: {
          name,
          content: JSON.stringify(i18nFromSettings),
          contentType,
        },
      }) as any,
    )) as QueryActionCreatorResult<any>;
    uploadFilePost.unsubscribe();
    // Wait until the table data is fetched and the grid is updated
    yield uploadFilePost;

    yield put(closeDialog());

    yield put(reset(Forms.I18n));
  } catch (error) {
    const { errorMessage = defaultErrorMessage } = extractErrorProps(error);
    yield call(toast.error, errorMessage);
  }
}

function* changeLanguage({ resolve, values: { [LanguageDialogFormFields.Language]: language } }: any): Generator {
  i18n.changeLanguage(language);
  yield call(resolve);
  yield put(closeDialog());
}

function* watchRemoveWhatsNew(): Generator {
  yield takeEvery(SettingsActionTypes.REMOVE_WHATS_NEW, removeWhatsNew);
}

function* watchSubmitWhatsNewForm(): Generator {
  yield takeLatest(`${Forms.WhatsNew}_SUBMIT`, saveWhatsNew);
}

function* watchOpenSalesViewWhatsNewDialog(): Generator {
  yield takeEvery(SettingsActionTypes.OPEN_SALESVIEW_WHATS_NEW, openSalesViewWhatsNewDialog);
}

function* watchFetchSceneEnvironment(): Generator {
  yield takeLatest(SettingsActionTypes.FETCH_SCENE_ENVIRONMENT, fetchSceneEnvironment);
}

function* watchRemoveSceneEnvironment(): Generator {
  yield takeEvery(SettingsActionTypes.REMOVE_SCENE_ENVIRONMENT, removeSceneEnvironment);
}

function* watchSubmitSceneEnvironmentForm(): Generator {
  yield takeLatest(`${Forms.SceneEnvironment}_SUBMIT`, saveSceneEnvironment);
}

function* watchFetchI18nValues(): Generator {
  yield takeEvery(SettingsActionTypes.FETCH_I18N_VALUES, fetchI18nValues);
}

function* watchSubmitI18nForm(): Generator {
  yield takeLatest(`${Forms.I18n}_SUBMIT`, saveSalesViewI18n);
}

function* watchDeleteI18nKey(): Generator {
  yield takeEvery(SettingsActionTypes.DELETE_I18N_KEY, deleteI18nKey);
}

export function* watchSubmitLanguage(): Generator {
  yield takeEvery(`${Forms.Language}_SUBMIT`, changeLanguage);
}

export function* settingsSaga(): Generator {
  yield all([
    watchRemoveWhatsNew(),
    watchSubmitWhatsNewForm(),
    watchSubmitLanguage(),
    watchOpenSalesViewWhatsNewDialog(),
    watchFetchSceneEnvironment(),
    watchRemoveSceneEnvironment(),
    watchSubmitSceneEnvironmentForm(),
    watchFetchI18nValues(),
    watchSubmitI18nForm(),
    watchDeleteI18nKey(),
  ]);
}
