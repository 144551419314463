/* eslint-disable react-hooks/rules-of-hooks */

import { OnlyExistingKeys } from '../types/Repo';
import { useAppSelector } from '../hooks';
import { WhatsNew } from '../types/WhatsNew';
import { SceneEnvironment } from '../types/SceneEnvironment';
import { useGetLatestWhatsNewQuery, useGetWhatsNewQuery, useListSceneEnvironmentsQuery } from '../services/settingApi';

/**
 * Using constant empty var references so the hook doesn't generate new references on every render
 * Allowing us to use the returned values from this hook inside useEffect params.
 */
const EMPTY_WHATS_NEW_REFERENCE: ReadonlyArray<WhatsNew> = [];
const EMPTY_SCENE_ENVIRONMENTS_REFERENCE: ReadonlyArray<SceneEnvironment> = [];

type UseSettingRepoReturn<UseWhatsNewType, UseLatestWhatsNewType, UseSceneEnvironmentsType> = OnlyExistingKeys<{
  whatsNew: UseWhatsNewType extends true ? WhatsNew[] : never;
  isLoadingWhatsNew: UseWhatsNewType extends true ? boolean : never;
  latestWhatsNew: UseLatestWhatsNewType extends true ? WhatsNew : never;
  isLoadingLatestWhatsNew: UseLatestWhatsNewType extends true ? boolean : never;
  sceneEnvironments: UseSceneEnvironmentsType extends true ? SceneEnvironment[] : never;
  isLoadingSceneEnvironments: UseSceneEnvironmentsType extends true ? boolean : never;
}>;

/**
 * Hook for fetching settings data.
 * The useData... params are used so we don't end up subscribing this component to unnecessary queries.
 *
 * @param useData param used to specify the data the component needs to fetch.
 */
export const useSettingRepo = <
  UseWhatsNewType extends boolean = false,
  UseLatestWhatsNewType extends boolean = false,
  UseSceneEnvironmentsType extends boolean = false,
>({
  useWhatsNew,
  useLatestWhatsNew,
  useSceneEnvironments,
}: {
  useWhatsNew?: UseWhatsNewType;
  useLatestWhatsNew?: UseLatestWhatsNewType;
  useSceneEnvironments?: UseSceneEnvironmentsType;
} = {}): UseSettingRepoReturn<UseWhatsNewType, UseLatestWhatsNewType, UseSceneEnvironmentsType> => {
  const clientId = useAppSelector((state) => state.viewer.selectedClientId || '');

  const result: any = {};
  if (useWhatsNew) {
    const { data: whatsNew = EMPTY_WHATS_NEW_REFERENCE, isFetching: isLoadingWhatsNew } = useGetWhatsNewQuery(
      { clientId },
      {
        skip: !clientId,
        refetchOnFocus: false,
      },
    );
    result.whatsNew = whatsNew;
    result.isLoadingWhatsNew = isLoadingWhatsNew;
  }

  if (useLatestWhatsNew) {
    const { data: latestWhatsNew = EMPTY_WHATS_NEW_REFERENCE, isFetching: isLoadingLatestWhatsNew } =
      useGetLatestWhatsNewQuery(
        { clientId },
        {
          skip: !clientId,
          refetchOnFocus: false,
        },
      );
    result.latestWhatsNew = latestWhatsNew;
    result.isLoadingLatestWhatsNew = isLoadingLatestWhatsNew;
  }

  if (useSceneEnvironments) {
    const { data: sceneEnvironments = EMPTY_SCENE_ENVIRONMENTS_REFERENCE, isFetching: isLoadingSceneEnvironments } =
      useListSceneEnvironmentsQuery(
        { clientId },
        {
          skip: !clientId,
          refetchOnFocus: false,
        },
      );
    result.sceneEnvironments = sceneEnvironments;
    result.isLoadingSceneEnvironments = isLoadingSceneEnvironments;
  }

  return result;
};
