import { AnyAction, Reducer } from 'redux';
import { action } from 'typesafe-actions';
import { FormData } from '../components/I18nForm';
import { unknownI18nDialog } from '../constants/I18n';
import { unknownSceneEnvironment } from '../constants/SceneEnvironment';
import { unknownWhatsNew } from '../constants/WhatsNew';
import { I18n, I18nValue } from '../types/I18n';
import { SceneEnvironment } from '../types/SceneEnvironment';
import { SettingsState } from '../types/SettingsState';
import { WhatsNew } from '../types/WhatsNew';

// Action types

export enum SettingsActionTypes {
  ADD_WHATS_NEW = 'ADD_WHATS_NEW',
  ADD_WHATS_NEW_ERROR = 'ADD_WHATS_NEW_ERROR',
  EDIT_WHATS_NEW = 'EDIT_WHATS_NEW',
  EDIT_WHATS_NEW_ERROR = 'EDIT_WHATS_NEW_ERROR',
  REMOVE_WHATS_NEW = 'REMOVE_WHATS_NEW',
  SET_WHATS_NEW_DIALOG = 'SET_WHATS_NEW_DIALOG',
  OPEN_SALESVIEW_WHATS_NEW = 'OPEN_SALESVIEW_WHATS_NEW',
  OPEN_SALESVIEW_WHATS_NEW_SUCCESS = 'OPEN_SALESVIEW_WHATS_NEW_SUCCESS',
  OPEN_SALESVIEW_WHATS_NEW_ERROR = 'OPEN_SALESVIEW_WHATS_NEW_ERROR',
  FETCH_SCENE_ENVIRONMENT = 'FETCH_SCENE_ENVIRONMENT',
  FETCH_SCENE_ENVIRONMENT_ERROR = 'FETCH_SCENE_ENVIRONMENT_ERROR',
  FETCH_SCENE_ENVIRONMENT_SUCCESS = 'FETCH_SCENE_ENVIRONMENT_SUCCESS',
  ADD_SCENE_ENVIRONMENT = 'ADD_SCENE_ENVIRONMENT',
  ADD_SCENE_ENVIRONMENT_ERROR = 'ADD_SCENE_ENVIRONMENT_ERROR',
  EDIT_SCENE_ENVIRONMENT = 'EDIT_SCENE_ENVIRONMENT',
  EDIT_SCENE_ENVIRONMENT_ERROR = 'EDIT_SCENE_ENVIRONMENT_ERROR',
  REMOVE_SCENE_ENVIRONMENT = 'REMOVE_SCENE_ENVIRONMENT',
  REMOVE_SCENE_ENVIRONMENT_SUCCESS = 'REMOVE_SCENE_ENVIRONMENT_SUCCESS',
  SET_SCENE_ENVIRONMENT_DIALOG = 'SET_SCENE_ENVIRONMENT_DIALOG',
  FETCH_I18N_VALUES = 'FETCH_I18N_VALUES',
  FETCH_I18N_VALUES_SUCCESS = 'FETCH_I18N_VALUES_SUCCESS',
  FETCH_I18N_VALUES_ERROR = 'FETCH_I18N_VALUES_ERROR',
  SET_I18N_DIALOG = 'SET_I18N_DIALOG',
  DELETE_I18N_KEY = 'DELETE_I18N_KEY',
}

// Reducer

export const INITIAL_SETTINGS_STATE: SettingsState = {
  whatsNewDialog: unknownWhatsNew,
  whatsNewSalesViewDialog: [],
  sceneEnvironment: [],
  sceneEnvironmentDialog: unknownSceneEnvironment,
  i18n: {},
  i18nDialog: unknownI18nDialog,
  loading: false,
  loadingSceneEnvironment: false,
};

export const settingsReducer: Reducer<SettingsState> = (state = INITIAL_SETTINGS_STATE, settingsAction) => {
  switch (settingsAction.type) {
    case SettingsActionTypes.ADD_WHATS_NEW: {
      const { whatsNewDialog } = state;
      return { ...state, whatsNewDialog: { ...whatsNewDialog, updating: true } };
    }

    case SettingsActionTypes.ADD_WHATS_NEW_ERROR:
    case SettingsActionTypes.EDIT_WHATS_NEW_ERROR: {
      const { whatsNewDialog } = state;
      return { ...state, whatsNewDialog: { ...whatsNewDialog, updating: false } };
    }

    case SettingsActionTypes.EDIT_WHATS_NEW: {
      const {
        payload: { whatsNew },
      } = settingsAction;
      return { ...state, whatsNewDialog: { ...whatsNew, updating: true } };
    }

    case SettingsActionTypes.SET_WHATS_NEW_DIALOG: {
      const {
        payload: { clientId, whatsNewDialog },
      } = settingsAction;
      const whatsNew = whatsNewDialog || unknownWhatsNew;
      return { ...state, whatsNewDialog: { ...whatsNew, clientId } };
    }

    case SettingsActionTypes.OPEN_SALESVIEW_WHATS_NEW: {
      return { ...state, loading: true };
    }

    case SettingsActionTypes.OPEN_SALESVIEW_WHATS_NEW_SUCCESS: {
      const {
        payload: { whatsNewSalesViewDialog },
      } = settingsAction;
      return { ...state, whatsNewSalesViewDialog, loading: false };
    }

    case SettingsActionTypes.OPEN_SALESVIEW_WHATS_NEW_ERROR: {
      return { ...state, whatsNewSalesViewDialog: [], loading: false };
    }

    case SettingsActionTypes.FETCH_SCENE_ENVIRONMENT: {
      return { ...state, sceneEnvironment: [], loadingSceneEnvironment: true };
    }
    case SettingsActionTypes.FETCH_SCENE_ENVIRONMENT_SUCCESS: {
      const {
        payload: { sceneEnvironment },
      } = settingsAction;

      return { ...state, sceneEnvironment, loadingSceneEnvironment: false };
    }
    case SettingsActionTypes.FETCH_SCENE_ENVIRONMENT_ERROR: {
      return { ...state, sceneEnvironment: [], loadingSceneEnvironment: false };
    }

    case SettingsActionTypes.ADD_SCENE_ENVIRONMENT: {
      const { sceneEnvironmentDialog } = state;
      return { ...state, sceneEnvironmentDialog: { ...sceneEnvironmentDialog, updating: true } };
    }

    case SettingsActionTypes.ADD_SCENE_ENVIRONMENT_ERROR:
    case SettingsActionTypes.EDIT_SCENE_ENVIRONMENT_ERROR: {
      const { sceneEnvironmentDialog } = state;
      return { ...state, sceneEnvironmentDialog: { ...sceneEnvironmentDialog, updating: false } };
    }

    case SettingsActionTypes.EDIT_SCENE_ENVIRONMENT: {
      const {
        payload: { sceneEnvironment },
      } = settingsAction;
      return { ...state, sceneEnvironmentDialog: { ...sceneEnvironment, updating: true } };
    }

    case SettingsActionTypes.REMOVE_SCENE_ENVIRONMENT_SUCCESS: {
      const { sceneEnvironment } = state;

      if (sceneEnvironment !== undefined) {
        const {
          payload: { sceneEnvironment: sceneEnvironmentToRemove },
        } = settingsAction;

        const newSceneEnvironment = sceneEnvironment.filter((scene) => scene.key !== sceneEnvironmentToRemove.key);

        return {
          ...state,
          sceneEnvironment: newSceneEnvironment,
        };
      }

      return { ...state };
    }

    case SettingsActionTypes.SET_SCENE_ENVIRONMENT_DIALOG: {
      const {
        payload: { clientId, sceneEnvironmentDialog },
      } = settingsAction;
      const sceneEnvironment = sceneEnvironmentDialog || unknownSceneEnvironment;
      return {
        ...state,
        sceneEnvironmentDialog: { ...sceneEnvironment, clientId, isNew: sceneEnvironmentDialog === undefined },
      };
    }

    case SettingsActionTypes.FETCH_I18N_VALUES: {
      return { ...state, loading: true };
    }

    case SettingsActionTypes.FETCH_I18N_VALUES_SUCCESS: {
      const {
        payload: { i18n },
      } = settingsAction;
      return { ...state, i18n, loading: false };
    }

    case SettingsActionTypes.FETCH_I18N_VALUES_ERROR: {
      return { ...state, loading: false };
    }

    case SettingsActionTypes.SET_I18N_DIALOG: {
      const {
        payload: { clientId, language, i18nValues },
      } = settingsAction;
      return { ...state, i18nDialog: { clientId, language, ...i18nValues } };
    }

    default: {
      return state;
    }
  }
};

// Action creators

export const addWhatsNew = (): AnyAction => action(SettingsActionTypes.ADD_WHATS_NEW);

export const addWhatsNewError = (): AnyAction => action(SettingsActionTypes.ADD_WHATS_NEW_ERROR);

export const editWhatsNew = (whatsNew: WhatsNew): AnyAction => action(SettingsActionTypes.EDIT_WHATS_NEW, { whatsNew });

export const editWhatsNewError = (): AnyAction => action(SettingsActionTypes.EDIT_WHATS_NEW_ERROR);

export const removeWhatsNew = (whatsNew: WhatsNew): AnyAction =>
  action(SettingsActionTypes.REMOVE_WHATS_NEW, { whatsNew });
export const setWhatsNewDialog = (clientId?: string, whatsNewDialog?: WhatsNew): AnyAction =>
  action(SettingsActionTypes.SET_WHATS_NEW_DIALOG, { clientId, whatsNewDialog });

export const openSalesViewWhatsNew = (): AnyAction => action(SettingsActionTypes.OPEN_SALESVIEW_WHATS_NEW);

export const openSalesViewWhatsNewSuccess = (whatsNewSalesViewDialog: WhatsNew[]): AnyAction =>
  action(SettingsActionTypes.OPEN_SALESVIEW_WHATS_NEW_SUCCESS, { whatsNewSalesViewDialog });

export const openSalesViewWhatsNewError = (): AnyAction => action(SettingsActionTypes.OPEN_SALESVIEW_WHATS_NEW_ERROR);

export const fetchSceneEnvironment = (clientId: string): AnyAction =>
  action(SettingsActionTypes.FETCH_SCENE_ENVIRONMENT, { clientId });

export const fetchSceneEnvironmentError = (): AnyAction => action(SettingsActionTypes.FETCH_SCENE_ENVIRONMENT_ERROR);

export const fetchSceneEnvironmentSuccess = (sceneEnvironment: SceneEnvironment[]): AnyAction =>
  action(SettingsActionTypes.FETCH_SCENE_ENVIRONMENT_SUCCESS, { sceneEnvironment });

export const addSceneEnvironment = (): AnyAction => action(SettingsActionTypes.ADD_SCENE_ENVIRONMENT);

export const addSceneEnvironmentError = (): AnyAction => action(SettingsActionTypes.ADD_SCENE_ENVIRONMENT_ERROR);

export const editSceneEnvironment = (sceneEnvironment: SceneEnvironment): AnyAction =>
  action(SettingsActionTypes.EDIT_SCENE_ENVIRONMENT, { sceneEnvironment });

export const editSceneEnvironmentError = (): AnyAction => action(SettingsActionTypes.EDIT_SCENE_ENVIRONMENT_ERROR);

export const removeSceneEnvironment = (sceneEnvironment: SceneEnvironment): AnyAction =>
  action(SettingsActionTypes.REMOVE_SCENE_ENVIRONMENT, { sceneEnvironment });

export const removeSceneEnvironmentSuccess = (sceneEnvironment: SceneEnvironment): AnyAction =>
  action(SettingsActionTypes.REMOVE_SCENE_ENVIRONMENT_SUCCESS, { sceneEnvironment });

export const setSceneEnvironmentDialog = (clientId?: string, sceneEnvironmentDialog?: SceneEnvironment): AnyAction =>
  action(SettingsActionTypes.SET_SCENE_ENVIRONMENT_DIALOG, { clientId, sceneEnvironmentDialog });

export const fetchI18nValues = (language: string): AnyAction =>
  action(SettingsActionTypes.FETCH_I18N_VALUES, { language });

export const fetchI18nValuesSuccess = (i18n: I18n): AnyAction =>
  action(SettingsActionTypes.FETCH_I18N_VALUES_SUCCESS, { i18n });

export const fetchI18nValuesError = (): AnyAction => action(SettingsActionTypes.FETCH_I18N_VALUES_ERROR);

export const deleteI18nKey = (i18nFormData: FormData): AnyAction =>
  action(SettingsActionTypes.DELETE_I18N_KEY, { i18nFormData });

export const setI18nDialog = (clientId?: string, language?: string, i18nValues?: I18nValue): AnyAction =>
  action(SettingsActionTypes.SET_I18N_DIALOG, { clientId, language, i18nValues });
