import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import FilterListIcon from '@mui/icons-material/FilterList';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Divider, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FilterMenuItems } from '../constants/FilterMenuItems';
import { fetchOrders, setDateRange } from '../ducks/orders';
import { AppState } from '../types/AppState';
import { DateRange } from '../types/DateRange';
import { getCurrentMonthDateRange, getLastDaysDateRange, getPriorMonthDateRange } from '../utils/dateUtils';
import { DateRangeDialog } from './DateRangeDialog';
import { isCurrentUserManager, isCurrentUserAdmin } from '../utils/userUtils';
import { FilterSelectionDialog } from './FilterSelectionDialog';
import { CustomFilterType, GroupFilter } from '../types/CustomFilter';
import { unknownGroup } from '../constants/Group';
import { Dealer, unknownDealer } from '../types/Dealer';
import { OrderOwner, unknownOwner } from '../types/OrderOwner';
import { Configurator } from '../types/Configurator';
import { openDialog } from '../ducks/dialogSlice';
import { Dialogs } from '../constants/Dialogs';
import { OrderStatusFilter } from '../types/OrderStatus';
import { defaultStatusFilters } from '../constants/OrderStatus';
import { FilterType, TableFilterType } from '../constants/Viewer';
import { getFilterMessage } from '../utils/viewerUtils';
import { I18nKeys } from '../constants/I18nKeys';
import { mapSubmitStatusToLabel } from '../utils/orderUtils';
import { clearFilters, fetchGroupFilters, setFilterDialog } from '../ducks/viewerSlice';

interface OwnProps {
  count: number;
}

interface StateProps {
  dateRange?: DateRange;
  isAdmin: boolean;
  isManager: boolean;
  availableDealers: Dealer[];
  availableOwners: OrderOwner[];
  configurators: Configurator[];
  orderStatuses: OrderStatusFilter[];
  orderSubmitStatuses: string[];
  groupId: string | undefined;
  groupFilters: GroupFilter[];
}

interface DispatchProps {
  loadFilters(): void;
  handleDateRangeChange(dateRange?: DateRange): void;
  handleOpenCustomOrderFilter(groupId: string | undefined, filterType: string, availableValues: CustomFilterType): void;
  handleClearFilters(): void;
}

type Props = OwnProps & StateProps & DispatchProps;

const OrderFilterMenuComponent: React.FC<Props> = ({
  dateRange,
  isAdmin,
  isManager,
  availableDealers,
  availableOwners,
  configurators,
  orderStatuses,
  orderSubmitStatuses,
  groupId,
  groupFilters,
  count,
  loadFilters,
  handleDateRangeChange,
  handleOpenCustomOrderFilter,
  handleClearFilters,
}: Props) => {
  const { t } = useTranslation();
  const [filterMenuAnchorEl, setFilterMenuAnchorEl] = React.useState<null | HTMLDivElement>(null);
  const [selectedDateFilterMenuItem, setSelectedDateFilterMenuItem] = React.useState(FilterMenuItems.Days45);
  const [previousDateFilterMenuItem, setPreviousDateFilterMenuItem] = React.useState(FilterMenuItems.Days45);
  const [filterMessage, setFilterMessage] = React.useState<string>(t(I18nKeys.OrderFilterLast45Days));
  const [customFilterMessage, setCustomFilterMessage] = React.useState('');
  const [dateRangeDialogOpen, setDateRangeDialogOpen] = React.useState(false);
  const anchorElRef = React.createRef<HTMLDivElement>();

  // Get filters from localStorage if available
  // Set the custom filter message based on the filter type and number of filters
  // Sets the message in the format of 'for n filterType(s)'
  React.useEffect(() => {
    if (groupId && (!groupFilters || !groupFilters.find((groupFilter) => groupFilter.groupId === groupId))) {
      loadFilters();
    }
    setCustomFilterMessage(getFilterMessage(groupId, groupFilters, TableFilterType.Order));
  }, [groupId, groupFilters, loadFilters]);

  const handleFilterMenuClick = (): void => setFilterMenuAnchorEl(anchorElRef.current);
  const handleFilterMenuClose = (): void => setFilterMenuAnchorEl(null);

  const handleDateFilterMenuItemClick = (filter: FilterMenuItems): void => {
    setPreviousDateFilterMenuItem(selectedDateFilterMenuItem);
    setSelectedDateFilterMenuItem(filter);
    switch (filter) {
      case FilterMenuItems.Days45:
        handleDateRangeChange(getLastDaysDateRange(45));
        setFilterMessage(t(I18nKeys.OrderFilterLast45Days));
        break;
      case FilterMenuItems.Days90:
        handleDateRangeChange(getLastDaysDateRange(90));
        setFilterMessage(t(I18nKeys.OrderFilterLast90Days));
        break;
      case FilterMenuItems.MonthCurrent:
        handleDateRangeChange(getCurrentMonthDateRange());
        setFilterMessage(t(I18nKeys.OrderFilterThisMonth));
        break;
      case FilterMenuItems.MonthLast:
        handleDateRangeChange(getPriorMonthDateRange());
        setFilterMessage(t(I18nKeys.OrderFilterLastMonth));
        break;
      case FilterMenuItems.AllTime:
        handleDateRangeChange(undefined);
        setFilterMessage(t(I18nKeys.OrderFilterAllTime));
        break;
      case FilterMenuItems.Custom:
        setDateRangeDialogOpen(true);
        break;
      default:
    }
    handleFilterMenuClose();
  };

  const handleCustomFilterMenuItemClick = (filter: FilterType): void => {
    switch (filter) {
      case FilterType.Dealer:
        handleOpenCustomOrderFilter(groupId, FilterType.Dealer, availableDealers);
        break;
      case FilterType.Owner:
        handleOpenCustomOrderFilter(groupId, FilterType.Owner, availableOwners);
        break;
      case FilterType.Site:
        handleOpenCustomOrderFilter(groupId, FilterType.Site, configurators);
        break;
      case FilterType.Status:
        handleOpenCustomOrderFilter(groupId, FilterType.Status, orderStatuses);
        break;
      case FilterType.SubmitStatus:
        handleOpenCustomOrderFilter(groupId, FilterType.SubmitStatus, orderSubmitStatuses);
        break;
      default:
    }
    handleFilterMenuClose();
  };

  const handleClearFilterMenuItemClick = (): void => {
    handleClearFilters();
    handleFilterMenuClose();
  };

  const transparentFilterIconColor = (filterMenuItem: FilterMenuItems | FilterType): string | undefined => {
    const filtersForGroup = groupFilters.find((groupFilter) => groupFilter.groupId === groupId);
    const filtersForTable = filtersForGroup
      ? filtersForGroup.tableFilters.find((tableFilter) => tableFilter.tableFilterType === TableFilterType.Order)
      : undefined;
    const filtersForMenuItem = filtersForTable
      ? filtersForTable.filters.find((filter) => filter.filterType === filterMenuItem)
      : undefined;
    return filterMenuItem !== selectedDateFilterMenuItem && !filtersForMenuItem ? 'transparent' : undefined;
  };

  const handleDateRangeDialogCancel = (): void => {
    setSelectedDateFilterMenuItem(previousDateFilterMenuItem);
    setDateRangeDialogOpen(false);
  };

  const handleDateRangeDialogApply = (newDateRange: DateRange): void => {
    handleDateRangeChange(newDateRange);
    if (newDateRange && newDateRange.startDate && newDateRange.endDate) {
      setFilterMessage(`${newDateRange.startDate.format('MM/DD/YYYY')} - ${newDateRange.endDate.format('MM/DD/YYYY')}`);
    }
    setDateRangeDialogOpen(false);
  };

  const filters = [
    { filter: FilterMenuItems.Days45, label: t(I18nKeys.FilterBy45Days) },
    { filter: FilterMenuItems.Days90, label: t(I18nKeys.FilterBy90Days) },
    { filter: FilterMenuItems.MonthCurrent, label: t(I18nKeys.FilterByThisMonth) },
    { filter: FilterMenuItems.MonthLast, label: t(I18nKeys.FilterByLastMonth) },
    // Disabling ALL_TIME for now because it always ends up eventually being a performance problem.
    // leaving the code in place though in case we re-consider.
    { filter: FilterMenuItems.AllTime, label: t(I18nKeys.FilterByAllTime), disabled: true },
    { filter: FilterMenuItems.Custom, label: t(I18nKeys.FilterByCustomDates) },
    { filter: FilterType.Dealer, label: t(I18nKeys.FilterByDealer), disabled: availableDealers.length <= 1 },
    {
      filter: FilterType.Owner,
      label: t(I18nKeys.FilterByOwner),
      disabled: availableOwners.length <= 1 || (!isAdmin && !isManager),
    },
    {
      filter: FilterType.Site,
      label: t(I18nKeys.FilterBySite),
      disabled: configurators.length <= 1,
    },
    { filter: FilterType.Status, label: t(I18nKeys.FilterByStatus) },
    { filter: FilterType.SubmitStatus, label: t(I18nKeys.FilterBySubmitStatus) },
  ];

  return (
    <Grid container direction="column">
      <Grid container item alignItems="center">
        <IconButton aria-controls="filter-menu" aria-haspopup="true" onClick={handleFilterMenuClick} size="large">
          <FilterListIcon />
        </IconButton>
        <Menu
          id="filter-menu"
          anchorEl={filterMenuAnchorEl}
          open={Boolean(filterMenuAnchorEl)}
          onClose={handleFilterMenuClose}
        >
          {filters.map(({ filter, label, disabled }, i) => {
            if (disabled) return null;
            return (
              <>
                <MenuItem
                  key={filter}
                  style={{ paddingRight: '40px' }}
                  onClick={(): void => {
                    if (Object.values(FilterMenuItems).includes(filter)) {
                      handleDateFilterMenuItemClick(filter as FilterMenuItems);
                    } else {
                      handleCustomFilterMenuItemClick(filter as FilterType);
                    }
                  }}
                >
                  <ListItemIcon style={{ color: transparentFilterIconColor(filter) }}>
                    <CheckIcon />
                  </ListItemIcon>
                  <Typography>{label}</Typography>
                </MenuItem>
                {filter === FilterMenuItems.Custom && <Divider />}
              </>
            );
          })}
          <MenuItem style={{ paddingRight: '40px' }} onClick={(): void => handleClearFilterMenuItemClick()}>
            <ListItemIcon>
              <ClearIcon />
            </ListItemIcon>
            <Typography>{t(I18nKeys.ClearFiltersButton)}</Typography>
          </MenuItem>
        </Menu>
        <Typography color="textSecondary" variant="body2">
          {t(I18nKeys.FilterMessage, { count, customFilterMessage, filterMessage })}
        </Typography>
        <DateRangeDialog
          open={dateRangeDialogOpen}
          onApply={handleDateRangeDialogApply}
          onCancel={handleDateRangeDialogCancel}
          dateRange={dateRange || { startDate: moment().subtract(45, 'days'), endDate: moment() }}
        />
        <FilterSelectionDialog onApply={fetchOrders} />
      </Grid>
      <Grid>
        <div style={{ position: 'relative', bottom: '-20px' }} ref={anchorElRef} />
      </Grid>
    </Grid>
  );
};

const mapStateToProps = ({
  currentUser: { user, availableDealers = [], group: { configurators = [], groupId } = unknownGroup },
  orders: { orders, dateRange, orderOwnerOptions },
  viewer: { groupFilters },
}: AppState): StateProps => {
  const isAdmin = isCurrentUserAdmin(user);
  const isManager = isCurrentUserManager(user);
  const orderStatuses = defaultStatusFilters;
  const submitStatusLabels: string[] = [];
  orders.forEach((order) => {
    if (
      (order.orderStatusId || order.orderStatusName) &&
      !orderStatuses.some((status) => status.key === order.orderStatusId) &&
      !orderStatuses.some((status) => status.name === order.orderStatusName)
    ) {
      orderStatuses.push({
        key: order.orderStatusId,
        name: order.orderStatusName,
        color: order.orderStatusBackgroundColor,
      });
    }
    submitStatusLabels.push(mapSubmitStatusToLabel(order.submitStatus));
  });
  orderStatuses.slice(0).sort((a: OrderStatusFilter, b: OrderStatusFilter) => (a.name > b.name ? 1 : -1));

  return {
    dateRange,
    isAdmin,
    isManager,
    availableDealers: [unknownDealer, ...availableDealers],
    availableOwners: [unknownOwner, ...orderOwnerOptions],
    configurators,
    orderStatuses,
    orderSubmitStatuses: [...new Set(submitStatusLabels)],
    groupId: groupId !== '' ? groupId : undefined,
    groupFilters,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  loadFilters: (): void => {
    dispatch(fetchGroupFilters());
    dispatch(fetchOrders());
  },
  handleDateRangeChange: (dateRange?: DateRange): void => {
    dispatch(setDateRange(dateRange));
    dispatch(fetchOrders());
  },
  handleOpenCustomOrderFilter: (
    groupId: string | undefined,
    filterType: string,
    availableValues: CustomFilterType,
  ): void => {
    dispatch(
      setFilterDialog({
        groupId,
        tableFilterType: TableFilterType.Order,
        filterType,
        availableFilterValues: availableValues,
      }),
    );
    dispatch(openDialog({ dialog: Dialogs.FilterSelection }));
  },
  handleClearFilters: (): void => {
    dispatch(clearFilters(TableFilterType.Order));
    dispatch(fetchOrders());
  },
});

export const OrderFilterMenu = connect(mapStateToProps, mapDispatchToProps)(OrderFilterMenuComponent);
