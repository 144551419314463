import { createApi } from '@reduxjs/toolkit/query/react';
import { API_NAMES } from '../constants/App';
import { amplifyAPIBaseQuery, getRequestHeader } from '../utils/apiUtils';
import { WhatsNew } from '../types/WhatsNew';
import { SceneEnvironment } from '../types/SceneEnvironment';
import { WhatsNewCacheTagType } from '../constants/WhatsNew';
import { SceneEnvironmentCacheTagType } from '../constants/SceneEnvironment';

export const settingApi = createApi({
  reducerPath: 'settingApi',
  refetchOnFocus: true,
  refetchOnReconnect: true,
  tagTypes: [
    WhatsNewCacheTagType.WhatsNew,
    WhatsNewCacheTagType.LatestWhatsNew,
    SceneEnvironmentCacheTagType.SceneEnvironments,
  ],
  baseQuery: amplifyAPIBaseQuery({
    apiName: API_NAMES.API_PUBLIC,
    baseUrl: '/v1/internal/settings',
  }),
  endpoints: (builder) => ({
    /**
     * Get What's New.
     *
     * @param clientId
     * @returns
     */
    getWhatsNew: builder.query<
      WhatsNew[],
      {
        clientId: string;
      }
    >({
      query: ({ clientId }) => ({
        url: `/whats-new`,
        method: 'get',
        init: {
          headers: getRequestHeader({ clientId }),
        },
      }),
      providesTags: (result, _error, { clientId }) =>
        result ? [{ type: WhatsNewCacheTagType.WhatsNew, id: clientId }] : [],
    }),

    /**
     * Get latest What's New.
     *
     * @param clientId
     * @param count
     * @returns
     */
    getLatestWhatsNew: builder.query<
      WhatsNew,
      {
        clientId: string;
        count?: number;
      }
    >({
      query: ({ clientId, count }) => ({
        url: `/whats-new/latest`,
        method: 'get',
        init: {
          headers: getRequestHeader({ clientId, count: count || 1 }),
        },
      }),
      providesTags: (result, _error, { clientId }) =>
        result ? [{ type: WhatsNewCacheTagType.LatestWhatsNew, id: clientId }] : [],
    }),

    /**
     * Add What's New.
     *
     * @param clientId
     * @param whatsNew
     * @param createdBy
     * @returns
     */
    addWhatsNew: builder.mutation<
      void,
      {
        groupId: string;
        clientId: string;
        whatsNew: WhatsNew[];
      }
    >({
      query: ({ groupId, clientId, whatsNew }) => ({
        url: `/whats-new`,
        method: 'post',
        init: {
          headers: getRequestHeader({ clientId, groupId }),
          body: {
            whatsNew,
          },
        },
      }),
      invalidatesTags: (_result, _error, { clientId }) => [
        { type: WhatsNewCacheTagType.LatestWhatsNew, id: clientId },
        { type: WhatsNewCacheTagType.WhatsNew, id: clientId },
      ],
    }),

    /**
     * Update What's New.
     *
     * @param clientId
     * @param whatsNew
     * @param createdBy
     * @returns
     */
    updateWhatsNew: builder.mutation<
      void,
      {
        groupId: string;
        clientId: string;
        id: string;
        whatsNew: WhatsNew;
      }
    >({
      query: ({ groupId, clientId, id, whatsNew }) => ({
        url: `/whats-new/${id}`,
        method: 'put',
        init: {
          headers: getRequestHeader({ clientId, groupId }),
          body: {
            whatsNew,
          },
        },
      }),
      invalidatesTags: (_result, _error, { clientId }) => [
        { type: WhatsNewCacheTagType.LatestWhatsNew, id: clientId },
        { type: WhatsNewCacheTagType.WhatsNew, id: clientId },
      ],
    }),

    /**
     * Delete What's New.
     *
     * @param clientId
     * @param id
     * @returns
     */
    deleteWhatsNew: builder.mutation<
      void,
      {
        groupId: string;
        clientId: string;
        id: string;
      }
    >({
      query: ({ groupId, clientId, id }) => ({
        url: `/whats-new/${id}`,
        method: 'del',
        init: {
          headers: getRequestHeader({ groupId, clientId }),
        },
      }),
      invalidatesTags: (_result, _error, { groupId, clientId }) => [
        { type: WhatsNewCacheTagType.LatestWhatsNew, id: clientId },
        { type: WhatsNewCacheTagType.WhatsNew, id: clientId },
      ],
    }),

    /**
     * List all Scene Environments
     *
     * @param clientId
     * @returns
     */
    listSceneEnvironments: builder.query<
      SceneEnvironment[],
      {
        clientId: string;
      }
    >({
      query: ({ clientId }) => ({
        url: `/scene-environment`,
        method: 'get',
        init: {
          headers: getRequestHeader({ clientId }),
        },
      }),
      providesTags: (result, _error, { clientId }) =>
        result ? [{ type: SceneEnvironmentCacheTagType.SceneEnvironments, id: clientId }] : [],
    }),

    /**
     * Add Scene Environment
     *
     * @param clientId
     * @param sceneEnvironment
     * @returns
     */
    addSceneEnvironment: builder.mutation<
      void,
      {
        clientId: string;
        sceneEnvironment: SceneEnvironment[];
      }
    >({
      query: ({ clientId, sceneEnvironment }) => ({
        url: `/scene-environment`,
        method: 'post',
        init: {
          headers: getRequestHeader({ clientId }),
          body: {
            sceneEnvironment,
          },
        },
      }),
      invalidatesTags: (_result, _error, { clientId }) => [
        { type: SceneEnvironmentCacheTagType.SceneEnvironments, id: clientId },
      ],
    }),

    /**
     * Update Scene Environment
     *
     * @param clientId
     * @param sceneEnvironment
     * @returns
     */
    updateSceneEnvironment: builder.mutation<
      void,
      {
        groupId: string;
        clientId: string;
        sceneEnvironmentKey: string;
        sceneEnvironment: SceneEnvironment;
      }
    >({
      query: ({ groupId, clientId, sceneEnvironmentKey, sceneEnvironment }) => ({
        url: `/scene-environment/${sceneEnvironmentKey}`,
        method: 'put',
        init: {
          headers: getRequestHeader({ clientId, groupId }),
          body: {
            sceneEnvironment,
          },
        },
      }),
      invalidatesTags: (_result, _error, { clientId }) => [
        { type: SceneEnvironmentCacheTagType.SceneEnvironments, id: clientId },
      ],
    }),

    /**
     * Delete Scene Environment
     *
     * @param clientId
     * @param sceneEnvironmentKey
     * @returns
     */
    deleteSceneEnvironment: builder.mutation<
      void,
      {
        groupId: string;
        clientId: string;
        sceneEnvironmentKey: string;
      }
    >({
      query: ({ groupId, clientId, sceneEnvironmentKey }) => ({
        url: `/scene-environment/${sceneEnvironmentKey}`,
        method: 'del',
        init: {
          headers: getRequestHeader({ clientId, groupId }),
        },
      }),
      invalidatesTags: (_result, _error, { clientId }) => [
        { type: SceneEnvironmentCacheTagType.SceneEnvironments, id: clientId },
      ],
    }),
  }),
});

export const {
  useGetWhatsNewQuery,
  useGetLatestWhatsNewQuery,
  useAddWhatsNewMutation,
  useUpdateWhatsNewMutation,
  useDeleteWhatsNewMutation,
  useListSceneEnvironmentsQuery,
  useAddSceneEnvironmentMutation,
  useUpdateSceneEnvironmentMutation,
  useDeleteSceneEnvironmentMutation,
} = settingApi;
