import moment from 'moment';
import { Reducer } from 'redux';
import { action } from 'typesafe-actions';
import { DateRange } from '../types/DateRange';
import { Order } from '../types/Order';
import { OrderDealer } from '../types/OrderDealer';
import { OrderOwner } from '../types/OrderOwner';
import { OrdersState } from '../types/OrdersState';
import { OrderStatus } from '../types/OrderStatus';
import { SortProperty } from '../types/SortProperty';

// Action types

export enum OrderActionTypes {
  FETCH_ORDERS = 'FETCH_ORDERS',
  FETCH_ORDERS_ERROR = 'FETCH_ORDERS_ERROR',
  FETCH_ORDERS_SUCCESS = 'FETCH_ORDERS_SUCCESS',
  FETCH_ORDERS_CSV = 'FETCH_ORDERS_CSV',
  SET_SORT_PROPERTIES = 'SET_SORT_PROPERTIES',
  SET_DATE_RANGE = 'SET_DATE_RANGE',
  FETCH_ORDER_OWNER_OPTIONS = 'FETCH_ORDER_OWNER_OPTIONS',
  FETCH_ORDER_OWNER_OPTIONS_ERROR = 'FETCH_ORDER_OWNER_OPTIONS_ERROR',
  FETCH_ORDER_OWNER_OPTIONS_SUCCESS = 'FETCH_ORDER_OWNER_OPTIONS_SUCCESS',
  SET_ORDER_DEALER = 'SET_ORDER_DEALER',
  SET_ORDER_DEALER_SUCCESS = 'SET_ORDER_DEALER_SUCCESS',
  SET_ORDER_OWNER = 'SET_ORDER_OWNER',
  SET_ORDER_OWNER_SUCCESS = 'SET_ORDER_OWNER_SUCCESS',
  SET_ORDER_STATUS = 'SET_ORDER_STATUS',
  SET_ORDER_STATUS_SUCCESS = 'SET_ORDER_STATUS_SUCCESS',
  OPEN_DETAILS_DIALOG = 'OPEN_DETAILS_DIALOG',
  OPEN_DETAILS_DIALOG_FROM_UUID = 'OPEN_DETAILS_DIALOG_FROM_UUID',
  CLOSE_DETAILS_DIALOG = 'CLOSE_DETAILS_DIALOG',
  DELETE_LEAD = 'DELETE_LEAD',
  DELETE_LEAD_SUCCESS = 'DELETE_LEAD_SUCCESS',
}

// Reducer

export const INITIAL_ORDERS_STATE: OrdersState = {
  configurator: undefined,
  loading: false,
  orders: [],
  sortProperties: [],
  vendor: undefined,
  dateRange: {
    startDate: moment().subtract(45, 'days').startOf('day'),
    endDate: moment().endOf('day'),
  },
  orderOwnerOptions: [],
  detailsDialogOpen: false,
  detailsState: undefined,
};

export const ordersReducer: Reducer<OrdersState> = (state = INITIAL_ORDERS_STATE, orderAction) => {
  switch (orderAction.type) {
    case OrderActionTypes.FETCH_ORDERS: {
      return { ...state, orders: [], loading: true };
    }

    case OrderActionTypes.FETCH_ORDERS_ERROR: {
      return { ...state, orders: [], loading: false };
    }

    case OrderActionTypes.FETCH_ORDERS_SUCCESS: {
      const {
        payload: { orders },
      } = orderAction;

      return { ...state, orders, loading: false };
    }

    case OrderActionTypes.SET_SORT_PROPERTIES: {
      const {
        payload: { sortProperties },
      } = orderAction;

      return { ...state, sortProperties };
    }

    case OrderActionTypes.SET_DATE_RANGE: {
      const {
        payload: { dateRange },
      } = orderAction;

      return { ...state, dateRange };
    }

    case OrderActionTypes.FETCH_ORDER_OWNER_OPTIONS_ERROR: {
      return { ...state, orderOwnerOptions: [] };
    }

    case OrderActionTypes.FETCH_ORDER_OWNER_OPTIONS_SUCCESS: {
      const {
        payload: { orderOwnerOptions },
      } = orderAction;
      return { ...state, orderOwnerOptions };
    }

    case OrderActionTypes.SET_ORDER_DEALER_SUCCESS: {
      const {
        payload: { clientId, uuid, orderDealer },
      } = orderAction;

      const { orders } = state;
      const updatedOrder = orders.find((order) => order.clientId === clientId && order.uuid === uuid);
      if (updatedOrder) {
        const orderIndex = orders.findIndex((order) => order.clientId === clientId && order.uuid === uuid);
        updatedOrder.orderDealerKey = orderDealer && orderDealer.key ? orderDealer.key : null;
        updatedOrder.orderDealerName = orderDealer && orderDealer.name ? orderDealer.name : null;

        return {
          ...state,
          orders: [...orders.slice(0, orderIndex), updatedOrder, ...orders.slice(orderIndex + 1, orders.length)],
        };
      }
      return { ...state, orders };
    }

    case OrderActionTypes.SET_ORDER_OWNER_SUCCESS: {
      const {
        payload: { clientId, uuid, orderOwner },
      } = orderAction;

      const { orders } = state;
      const updatedOrder = orders.find((order) => order.clientId === clientId && order.uuid === uuid);
      if (updatedOrder) {
        const orderIndex = orders.findIndex((order) => order.clientId === clientId && order.uuid === uuid);
        updatedOrder.ownerName = orderOwner && orderOwner.name ? orderOwner.name : null;
        updatedOrder.ownerEmail = orderOwner && orderOwner.email ? orderOwner.email : null;

        return {
          ...state,
          orders: [...orders.slice(0, orderIndex), updatedOrder, ...orders.slice(orderIndex + 1, orders.length)],
        };
      }
      return { ...state, orders };
    }

    case OrderActionTypes.SET_ORDER_STATUS_SUCCESS: {
      const {
        payload: { clientId, uuid, orderStatus },
      } = orderAction;

      const { orders } = state;
      const orderIndex = orders.findIndex((order) => order.clientId === clientId && order.uuid === uuid);
      if (orderIndex !== -1) {
        orders[orderIndex].orderStatusId = orderStatus && orderStatus.id ? orderStatus.id : null;
        orders[orderIndex].orderStatusI18nKey = orderStatus && orderStatus.i18nKey ? orderStatus.i18nKey : null;
        orders[orderIndex].orderStatusName = orderStatus && orderStatus.name ? orderStatus.name : null;
        orders[orderIndex].orderStatusFontColor =
          orderStatus && orderStatus.chip && orderStatus.chip.fontColor ? orderStatus.chip.fontColor : null;
        orders[orderIndex].orderStatusBackgroundColor =
          orderStatus && orderStatus.chip && orderStatus.chip.backgroundColor ? orderStatus.chip.backgroundColor : null;
      }
      return { ...state, orders };
    }

    case OrderActionTypes.OPEN_DETAILS_DIALOG: {
      const {
        payload: { order },
      } = orderAction;

      return { ...state, detailsDialogOpen: true, detailsState: order };
    }

    case OrderActionTypes.CLOSE_DETAILS_DIALOG: {
      return { ...state, detailsDialogOpen: false, detailsState: undefined };
    }

    case OrderActionTypes.DELETE_LEAD_SUCCESS: {
      const {
        payload: { uuid },
      } = orderAction;

      return { ...state, orders: state.orders.filter((order) => order.uuid !== uuid) };
    }

    default: {
      return state;
    }
  }
};

// Action creators

export type OrderActionType = { type: OrderActionTypes };

export const fetchOrders = (): OrderActionType => action(OrderActionTypes.FETCH_ORDERS);

export const fetchOrdersError = (): OrderActionType => action(OrderActionTypes.FETCH_ORDERS_ERROR);

export const fetchOrdersSuccess = (orders: Order[]): OrderActionType =>
  action(OrderActionTypes.FETCH_ORDERS_SUCCESS, { orders });

export const fetchOrdersCsv = (): OrderActionType => action(OrderActionTypes.FETCH_ORDERS_CSV);

export const setSortProperties = (sortProperties: SortProperty[]): OrderActionType =>
  action(OrderActionTypes.SET_SORT_PROPERTIES, { sortProperties });

export const setDateRange = (dateRange?: DateRange): OrderActionType =>
  action(OrderActionTypes.SET_DATE_RANGE, { dateRange });

export const fetchOrderOwnerOptions = (groupId?: string): OrderActionType =>
  action(OrderActionTypes.FETCH_ORDER_OWNER_OPTIONS, { groupId });

export const fetchOrderOwnerOptionsSuccess = (orderOwnerOptions: OrderOwner[]): OrderActionType =>
  action(OrderActionTypes.FETCH_ORDER_OWNER_OPTIONS_SUCCESS, { orderOwnerOptions });

export const fetchOrderOwnerOptionsError = (): OrderActionType =>
  action(OrderActionTypes.FETCH_ORDER_OWNER_OPTIONS_ERROR);

export const setOrderDealer = (
  clientId: string,
  uuid: string,
  status: string,
  orderDealer: OrderDealer,
): OrderActionType => action(OrderActionTypes.SET_ORDER_DEALER, { clientId, uuid, status, orderDealer });

export const setOrderDealerSuccess = (clientId: string, uuid: string, orderDealer: OrderDealer): OrderActionType =>
  action(OrderActionTypes.SET_ORDER_DEALER_SUCCESS, { clientId, uuid, orderDealer });

export const setOrderOwner = (
  clientId: string,
  uuid: string,
  status: string,
  orderOwner: OrderOwner,
): OrderActionType => action(OrderActionTypes.SET_ORDER_OWNER, { clientId, uuid, status, orderOwner });

export const setOrderOwnerSuccess = (clientId: string, uuid: string, orderOwner: OrderOwner): OrderActionType =>
  action(OrderActionTypes.SET_ORDER_OWNER_SUCCESS, { clientId, uuid, orderOwner });

export const setOrderStatus = (clientId: string, uuid: string, orderStatus: OrderStatus): OrderActionType =>
  action(OrderActionTypes.SET_ORDER_STATUS, { clientId, uuid, orderStatus });

export const setOrderStatusSuccess = (clientId: string, uuid: string, orderStatus: OrderStatus): OrderActionType =>
  action(OrderActionTypes.SET_ORDER_STATUS_SUCCESS, { clientId, uuid, orderStatus });

export const openOrderDetailDialog = (order: Order): OrderActionType =>
  action(OrderActionTypes.OPEN_DETAILS_DIALOG, { order });

export const openOrderDetailDialogFromUUID = (uuid: string): OrderActionType =>
  action(OrderActionTypes.OPEN_DETAILS_DIALOG_FROM_UUID, { uuid });

export const closeOrderDetailDialog = (): OrderActionType => action(OrderActionTypes.CLOSE_DETAILS_DIALOG);

export const deleteLead = (): OrderActionType => action(OrderActionTypes.DELETE_LEAD);

export const deleteLeadSuccess = (uuid: string): OrderActionType =>
  action(OrderActionTypes.DELETE_LEAD_SUCCESS, { uuid });
