import React from 'react';
import { Dialog } from './Dialog';
import { Dialogs } from '../constants/Dialogs';
import { closeDialog as closeDialogFunc, openDialog } from '../ducks/dialogSlice';
import { WhatsNew } from '../types/WhatsNew';
import { setWhatsNewDialog } from '../ducks/settings';
import { WhatsNewPreview } from './WhatsNewPreview';
import { useAppDispatch, useAppSelector } from '../hooks';
import { AppState } from '../types/AppState';

export const WhatsNewPreviewDialog: React.FC = () => {
  const { whatsNewSalesViewDialog: whatsNewRecords = [], loading } = useAppSelector(
    (state: AppState) => state?.settings,
  );

  const dispatch = useAppDispatch();
  const closeDialog = (whatsNew: WhatsNew): void => {
    dispatch(closeDialogFunc());
    dispatch(setWhatsNewDialog(whatsNew.clientId, whatsNew));
    dispatch(openDialog({ dialog: Dialogs.WhatsNew }));
  };

  return (
    <Dialog dialogKey={Dialogs.WhatsNewPreview}>
      <WhatsNewPreview loading={loading} whatsNewRecords={whatsNewRecords} closeDialog={closeDialog} />
    </Dialog>
  );
};
