export enum LocalStorage {
  SelectedGroupId = 'selectedGroupId',
  VisibleColumnKey = 'visibleColumns',
  WhatsNewKey = 'whatsNewKey',
  LastPricingBaseMergeCommit = 'lastPricingMergeCommit',
  LastPricingComponentMergeCommit = 'lastPricingComponentMergeCommit',
  LastPricingSizeBasedMergeCommit = 'lastPricingSizeBasedMergeCommit',
  TourIndex = 'tourIndex',
  GroupFilters = 'groupFilters',
  ClientData = 'clientData',
  ClientDataPreferences = 'clientDataPreferences',
  ClientDataNewSupplierUpdatesDialogSkip = 'clientDataNewSupplierUpdatesDialogSkip',
  ClientDataClipboardData = 'clientDataClipboardData',
  ClientDataCellHistorySize = 'clientDataCellHistorySize',
  HaveShownPricingBaseDeletingPriceDialog = 'haveShownPricingBaseDeletingPriceDialog',
}
