import { Fab, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import AddIcon from '@mui/icons-material/Add';
import React from 'react';
import { AnyAction } from 'redux';
import { Dialogs } from '../constants/Dialogs';
import { DockedDrawerWidth } from '../constants/DockedDrawerWidth';
import { openDialog } from '../ducks/dialogSlice';
import { setWhatsNewDialog } from '../ducks/settings';
import { AppState } from '../types/AppState';
import { unknownGroup } from '../constants/Group';
import { WhatsNew as WhatsNewType } from '../types/WhatsNew';
import { stringToLowerIncludes } from '../utils/stringUtils';
import { isIdeaRoomGroup } from '../utils/userUtils';
import { ConfiguratorAppBar } from './ConfiguratorAppBar';
import { WhatsNewAppBar } from './WhatsNewAppBar';
import { WhatsNewTable } from './WhatsNewTable';
import { setSelectedClientId as setSelectedClientIdFunc } from '../ducks/viewerSlice';
import { useAppDispatch, useAppSelector } from '../hooks';
import { getGroupSalesViewConfigurator } from '../utils/clientIdUtils';
import { useSettingRepo } from '../hooks/useSettingRepo';

const useStyles = makeStyles((theme: Theme) => ({
  fab: {
    position: 'fixed',
    [theme.breakpoints.down('lg')]: { bottom: theme.spacing(2), left: theme.spacing(2) },
    [theme.breakpoints.up('lg')]: { bottom: theme.spacing(2), left: theme.spacing(2 + DockedDrawerWidth / 8) },
  },
  fabText: {
    padding: theme.spacing(1),
  },
}));

export const WhatsNew: React.FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { whatsNew, isLoadingWhatsNew: loading } = useSettingRepo({
    useWhatsNew: true,
  });
  const { searchTerm } = useAppSelector((state: AppState) => state.search);
  const { group = unknownGroup } = useAppSelector((state: AppState) => state.currentUser);
  const { selectedClientId = '' } = useAppSelector((state: AppState) => state.viewer);

  const isIdearoomGroup = isIdeaRoomGroup(group.groupId);
  const configurators = [
    ...(group.configurators || []),
    getGroupSalesViewConfigurator(isIdeaRoomGroup(group.groupId) ? undefined : group.groupId),
  ];

  const [filteredWhatsNew, setFilteredWhatsNew] = React.useState<WhatsNewType[]>([]);

  const openWhatsNewDialog = (clientId: string): void => {
    dispatch(setWhatsNewDialog(clientId, undefined));
    dispatch(openDialog({ dialog: Dialogs.WhatsNew }));
  };
  const setSelectedClientId = (clientId: string): AnyAction => dispatch(setSelectedClientIdFunc(clientId));

  // Effect that filters What's New by searchTerm anytime either What's New or searchTerm change
  React.useEffect(() => {
    if (whatsNew) {
      if (searchTerm.length === 0) {
        setFilteredWhatsNew(whatsNew);
        return;
      }
      const tests = [
        (what: WhatsNewType): boolean => stringToLowerIncludes(what.title, searchTerm),
        (what: WhatsNewType): boolean => stringToLowerIncludes(what.message, searchTerm),
      ];

      setFilteredWhatsNew(whatsNew.filter((whatsNewRecord) => tests.some((test) => test(whatsNewRecord))));
    } else {
      setFilteredWhatsNew([]);
    }
  }, [whatsNew, searchTerm]);

  return (
    <>
      {!isIdearoomGroup && (
        <ConfiguratorAppBar enabledOnProperty="clientId" isVendorProperty={false} configurators={configurators} />
      )}
      {isIdearoomGroup && <WhatsNewAppBar onTabChange={setSelectedClientId} />}
      <WhatsNewTable loading={loading} clientId={selectedClientId} whatsNew={filteredWhatsNew} />
      <Fab
        color="primary"
        className={classes.fab}
        variant="extended"
        onClick={(): void => openWhatsNewDialog(selectedClientId)}
        aria-label="add what's new"
      >
        <AddIcon />
        <Typography className={classes.fabText}>Add</Typography>
      </Fab>
    </>
  );
};
