import React from 'react';
import { Dialog } from './Dialog';
import { Dialogs } from '../constants/Dialogs';
import { closeDialog as closeDialogFunc } from '../ducks/dialogSlice';
import { WhatsNewPreview } from './WhatsNewPreview';
import { useAppDispatch, useAppSelector } from '../hooks';
import { AppState } from '../types/AppState';

export const WhatsNewSalesViewDialog: React.FC = () => {
  const { whatsNewSalesViewDialog: whatsNewRecords = [], loading } = useAppSelector(
    (state: AppState) => state?.settings,
  );

  const dispatch = useAppDispatch();
  const closeDialog = () => dispatch(closeDialogFunc());

  return (
    <Dialog dialogKey={Dialogs.WhatsNewSalesView}>
      <WhatsNewPreview loading={loading} whatsNewRecords={whatsNewRecords} closeDialog={closeDialog} />
    </Dialog>
  );
};
